import { render, staticRenderFns } from "./NFTCard.vue?vue&type=template&id=1aec4435&scoped=true&"
import script from "./NFTCard.vue?vue&type=script&lang=js&"
export * from "./NFTCard.vue?vue&type=script&lang=js&"
import style0 from "./NFTCard.vue?vue&type=style&index=0&id=1aec4435&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aec4435",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VDivider,VHover,VProgressLinear,VScrollYTransition})
