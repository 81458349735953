import { nullOrString } from '@/lib/prop-validators';

export default { // TODO:: add favorite liking and disliking button
  props: {
    publicID: {
      type: [String, Number],
      required: true,
      default: () => (''),
    },
    contentType: {
      type: String,
      required: true,
      default: () => (''),
    },
    mimeType: {
      type: String,
      required: true,
    },
    itemName: {
      type: String,
      required: false,
      default: () => (''),
    },
    symbol: {
      type: String,
      required: false,
      default: () => (''),
    },
    contractAddress: {
      type: String,
      required: false,
      default: () => (''),
    },
    previewURL: {
      type: String,
      required: false,
      default: () => (''),
      validator: nullOrString,
    },
    contentURL: {
      type: String,
      required: false,
      default: () => (''),
    },
    imageHeight: {
      type: [String, Number],
      required: false,
      default: () => (300),
    },
    onSale: {
      type: Boolean,
      required: true,
    },
    likeable: {
      type: Boolean,
      default: () => (false),
    },
    likeCount: {
      type: Number,
      default: () => (0),
    },
    liked: {
      type: Boolean,
      default: () => (false),
    },
    value: {
      type: [String, Number],
      required: false,
    },
    usd_price: {
      type: [String, Number],
      required: false,
    },
    showFavourites: {
      type: Boolean,
      required: false,
    },
  },
};
