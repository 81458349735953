<template>
  <v-scroll-y-transition appear>
    <v-hover v-slot="{ hover }">
      <v-card
        :loading="loading"
        class="mx-auto fill-height d-flex flex-column flex-wrap w-100 nft-card"
        :class="hover ? 'elevation-3' : 'elevation-0'"
        width="100%"
        rounded="xl"
        transition="fade-transition"
        :to="`/assets/nfts/${publicID}`"
        outlined
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <nft-card-header v-bind="$props" />
        <nft-card-content v-bind="$props" />

        <template v-if="likeable">
          <v-divider class="mt-auto" />
          <nft-card-actions v-bind="$props"/>
        </template>
      </v-card>
    </v-hover>
  </v-scroll-y-transition>
</template>

<script>
import NFTCard from './NFTCard';

export default {
  name: 'NFTCard',
  mixins: [NFTCard],
  components: {
    'nft-card-header': () => import('./NFTCardHeader.vue'),
    'nft-card-content': () => import('./NFTCardContent.vue'),
    'nft-card-actions': () => import('./NFTCardActions.vue'),
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  computed: {
    actionsEnabled() {
      console.log(this.NFTCard);
      return this.showFavourites || true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';

.audioPlayer {
  position: absolute;
  width: 100%;
  top: 160px;
  z-index: 1;
}
.videoPlayer {
  position: absolute;
  max-height: 40;
  overflow: hidden;
  height: 40;
  width: 100%;
  z-index: 1;
}
</style>
